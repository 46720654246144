@font-face {
    font-family: 'NouvelR Bold';
    src: local('NouvelR Bold'),
    url(../fonts/nouvelr-bold.woff2) format("woff2");
}

@font-face {
    font-family: 'NouvelR Regular';
    src: local('NouvelR Regular'),
    url(../fonts/nouvelr-regular.woff2) format("woff2");
}

body {
    height: 100%;
    margin: 0;
    font-family: "NouvelR Regular", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 14px;
}

a {
    color: inherit; /* blue colors for links too */
    text-decoration: inherit; /* no underline */
}

.snackbar-success {
    background-color: #EFDF00 !important;
    color: black !important;
}
